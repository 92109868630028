// ----------------------------------------------------------------------

const account = {
  displayName: 'ABDULLA',
  email: 'abdulla@alinma.com',
  // photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  role:'admin'
};

export default account;
